<template>
  <BaseFormItem label="Hibob entities" class="flex-wrap">
    <div class="flex flex-col width-full">
      <div v-for="(item, $index) of modelValue" :key="$index" class="flex justify-between mb-1">
        <BaseFormItem prop="hibobEntities" :error="error" class="w-11/12">
          <BaseInput :model-value="item" @update:modelValue="input($event, $index)" name="hibobEntities" />
        </BaseFormItem>
        <button @click="remove($index)" type="button" class="flex-shrink-0 flex-grow-0 flex items-center justify-center w-1/12">
          <svg class="w-4 transition-colors duration-300 hover:text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </button>
      </div>
    </div>

    <p v-if="!error" class="mt-1 text-xs text-gray-500">Blank values will be ignored</p>
  </BaseFormItem>
</template>

<script>
export default {
  name: 'HibobEntityInput',
  props: {
    modelValue: {
      type: Array,
      default: () => ([])
    },
    error: {
      type: String,
      default: ''
    }
  },
  methods: {
    input (value, index) {
      const items = [...this.modelValue]
      items[index] = value

      if (index === items.length - 1) {
        items.push('')
      }

      this.$emit('update:modelValue', items)
    },
    remove (index) {
      const items = [...this.modelValue]
      items.splice(index, 1)

      if (index === items.length) {
        items.push('')
      }

      this.$emit('update:modelValue', items)
    }
  }
}
</script>
