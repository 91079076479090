import http from '@/utils/http'
import { getToken } from '@/utils/helpers'
import { getField, updateField } from 'vuex-map-fields'

const initialState = () => ({
  item: {
    endpoint: '',
    id: '',
    name: '',
    country: '',
    amadeusPropertyName: '',
    adyenMerchantAccountCodes: [],
    cornerstoneCompanyName: '',
    apicBaseOutletName: '',
    navCompanyName: '',
    nexudusBusinessName: '',
    apicBaseLibraries: [],
    apicBaseLibraryId: '',
    procoreProjectId: '',
    hibobEntities: [],
  },
  usedIntegrations: [],
  originalItem: {},
  errors: {}
})

export const state = initialState()

export const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_ITEM (state, item = null) {
    if (!item) {
      item = initialState().item
    }

    state.originalItem = { ...item }
    item.adyenMerchantAccountCodes.push('')
    item.hibobEntities.push('')
    state.item = { ...item }
    if (item.integrations) {
      state.usedIntegrations = [...item.integrations]
    }
  },
  SET_ITEM_WITH_DROPDOWN (state, data) {
    const item = initialState().item
    item.apicBaseLibraries = data.apicBaseLibraries
    item.adyenMerchantAccountCodes.push('')
    item.hibobEntities.push('')
    state.originalItem = { ...item }
    state.item = { ...item }
  },
  SET_USED_INTEGRATION (state, integration) {
    if (state.usedIntegrations.includes(integration)) {
      state.usedIntegrations.splice(state.usedIntegrations.indexOf(integration), 1)
    } else {
      state.usedIntegrations.push(integration)
    }
  },
  SET_ERRORS (state, errors = {}) {
    state.errors = errors
  },
  updateItemField (state, field) {
    updateField(state.item, field)
  }
}

export const getters = {
  getItemField: state => getField(state.item),
  item: state => state.item,
  originalItem: state => state.originalItem,
  usedIntegrations: state => state.usedIntegrations,
  errors: state => state.errors
}

export const actions = {
  getEntityInfo ({ commit }, entity) {
    return http({
      url: `${process.env.VUE_APP_API_URL}entity/${entity.id}`,
      headers: {
        'x-coretix-token': getToken()
      }
    }).then(data => {
      commit('SET_ITEM', {
        ...entity,
        ...data
      })
    })
  },
  saveEntity ({ getters }) {
    return http({
      method: 'post',
      url: `${process.env.VUE_APP_API_URL}entity`,
      headers: {
        'x-coretix-token': getToken()
      },
      data: {
        item: getters.item,
        originalItem: getters.originalItem,
        usedIntegrations: getters.usedIntegrations
      }
    })
  },
  removeEntity (context, entity) {
    return http({
      method: 'delete',
      url: `${process.env.VUE_APP_API_URL}entity/remove`,
      headers: {
        'x-coretix-token': getToken()
      },
      data: entity
    })
  },
  getApicBaseLibraries ({ commit }) {
    return http({
      url: `${process.env.VUE_APP_API_URL}entities/libraryList`,
      headers: {
        'x-coretix-token': getToken()
      }
    }).then(data => {
      commit('SET_ITEM_WITH_DROPDOWN', data)
    })
  }
}
